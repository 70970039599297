<template>
    <v-container fluid class="px-6" style="height: calc(100vh - 120px);overflow-y:auto;">

      <v-card elevation="2">

        <v-card-title class="px-6 card-header white--text">
            <v-icon class="mr-3 white--text">mdi-database-import-outline</v-icon> <h3>Calibration Record</h3>
        </v-card-title>

        <v-form class="main-form-card">
          <v-card class="mt-8 pa-5 mx-4" outlined>
            <v-container>
              <v-row>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                  v-on:change="selectValueStation"
                  :items="itemStation"
                  label="Station"
                  prepend-icon="mdi-store-24-hour"
                  required
                  outlined 
                  dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                  prepend-icon="mdi-calendar"
                  v-on:change="selectValueYearDate"
                  :items="dropdownYearDate"
                  label="Year"
                  class="input-date-year"
                  outlined 
                  dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                  prepend-icon="mdi-calendar"
                  v-on:change="selectValueMonthDate"
                  :items="dropdownMonthDate"
                  label="Month"
                  class="input-date-year"
                  outlined 
                  dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-btn :disabled="disableBtnGenerate" class="button white--text" @click="generateTable">
                    GENERATE
                  </v-btn>
                </v-col>

              </v-row>

              <v-data-table
              :headers="theadSummaryCalibration"
              :items="tbodySummaryCalibration"
              :header-props="{ sortIcon: null }"
              class="elevation-1 my-5 headerDtSarawak"
              :items-per-page="10"
              no-select-on-click
              fixed-header
              :loading="loadingTable"
              loading-text="Data is loading.."
              >

                <template v-slot:[`item.datetime`]="{ item }">
                    <span v-html="item.datetime"></span>
                  </template>

                  <template v-slot:[`item.fromDatetime`]="{ item }">
                    <span v-html="item.fromDatetime"></span>
                  </template>

                <template v-slot:[`item.checkbox`]="{ item }">
                    <v-checkbox
                    class="checkbox-dt"
                    :value="item.checkbox"
                    v-model="item.checkbox"
                    v-ripple
                    @change="check(item,$event)"
                    ></v-checkbox>
                </template>

              </v-data-table>

              <v-row justify="center">
                <v-btn
                class="button white--text mr-5"
                @click="allCheck"
                >{{textSelect}} ALL
                </v-btn>

                <v-btn
                class="button white--text"
                @click="unflag"
                >UNFLAG
                </v-btn>
              </v-row>

            </v-container>
          </v-card>
        </v-form>

        <br><br>

      </v-card>


      <!-- Popups/Dialogs Success Unflag Calibration Record-->
      <div class="text-center">
          <v-dialog
          v-model="dialogSuccessUnflag"
          width="500"
          >
          <v-card>
              <v-card-title class="text-h5 success white--text">
              Done! <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
              </v-card-title>

              <v-card-text style="padding:20px 24px;font-weight:bold;">
              Calibration is done.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  style="color:#106f79 !important;"
                  color="success"
                  text
                  @click="dialogSuccessUnflag = false;reloadPage();"
              >
                  OK
              </v-btn>
              </v-card-actions>
          </v-card>
          </v-dialog>
      </div>

      <!-- Popups/Dialogs Error Unflag Calibration Record-->
      <div class="text-center">
          <v-dialog
          v-model="dialogErrorUnflag"
          width="500"
          >
          <v-card>
              <v-card-title class="text-h5 error white--text">
              Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
              </v-card-title>

              <v-card-text style="padding:20px 24px;font-weight:bold;">
              Something when wrong. Please try again.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  style="color:red !important;"
                  color="error"
                  text
                  @click="dialogErrorUnflag = false;reloadPage();"
              >
                  OK
              </v-btn>
              </v-card-actions>
          </v-card>
          </v-dialog>
      </div>
        
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({

    itemStation: [],
    stationSelected: null,
    // monthDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
    yearDate: null,
    monthDate: null,
    dropdownYearDate: [],
    dropdownMonthDate: [
      "All Month",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    listMonthDate: [
      { id:"all", text:"All Month" },
      { id:"01", text:"January" },
      { id:"02", text:"February" },
      { id:"03", text:"March" },
      { id:"04", text:"April" },
      { id:"05", text:"May" },
      { id:"06", text:"June" },
      { id:"07", text:"July" },
      { id:"08", text:"August" },
      { id:"09", text:"September" },
      { id:"10", text:"October" },
      { id:"11", text:"November" },
      { id:"12", text:"December" }
    ],
    disableBtnGenerate: true,

    dataOnGoing: [],

    // Engine
    // modalStartCalendar: false,
    // modalEndCalendar: false,

    theadSummaryCalibration: [
      {text: "STATION ID", value: "stationId", sortable: true},
      {text: "Date & Time", value: "datetime", sortable: true},
      {text: "PARAMETER", value: "parameter", sortable: true},
      {text: "Start Date & Time", value: "fromDatetime", sortable: true},
      {text: "CREATED BY", value: "flagBy", sortable: true},
      {text: "ACTION", value: "checkbox", sortable: true},

    ],
    tbodySummaryCalibration: [],

    valueCheckbox: false,
    checkboxSelected: [],
    selectAll: false,
    textSelect: "SELECT",
    disableBtn: true,

    dialogSuccessUnflag: false,
    dialogErrorUnflag: false,
    loadingTable: false,

  }),
  methods:{

    load(){
      this.loadStationList();
      this.getApiDateYear();
      this.loadDataCalibrationRecord("All Stations",'all',this.thisYear());
    },

    thisYear(){
      const d = new Date();
      let year = d.getFullYear();

      return year;
    },

    getApiDateYear(){
          axios.get(this.globalUrl+'bakaj/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // this.stationDetails = response.data;
            console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
              this.dropdownYearDate.push(response.data[i]);
            }

            
            // console.log(this.itemStation);

        })
        .catch(error => {
            console.log(error);
        })
    },

    selectValueYearDate: function(e){
      this.yearDate = e;

      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    selectValueMonthDate: function(e){

      for(let i in this.listMonthDate){
        if (this.listMonthDate[i].text == e) {
          this.monthDate = this.listMonthDate[i].id;
        }
      }


      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    loadStationList(){
      
      this.itemStation = [];

      axios.get(this.globalUrl+'bakaj/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
          }

          this.itemStation.unshift("All Stations");
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    loadDataCalibrationRecord(station,month,year){

      axios.get(this.globalUrl+'maint/maintcal2?month='+month+'&year='+year, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // console.log(response.data);

          let data = response.data;
          this.tbodySummaryCalibration = [];
          this.dataOnGoing = [];

          console.log(data);

          for(let i in data){
            if (data[i]["datetime"] != null) {
              // res["datetime"] = res["datetime"].replaceAll("T", "<br>");
              data[i]["datetime"] = this.convertDateFormat(data[i]["datetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["datetime"].split("T")[1]);
            }
            if (data[i]["fromDatetime"] != null) {
              // data[i]["fromDatetime"] = data[i]["fromDatetime"].replaceAll("T", "<br>");
              data[i]["fromDatetime"] = this.convertDateFormat(data[i]["fromDatetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["fromDatetime"].split("T")[1]);
            }
          }

          for(let i in data){
            if (data[i].toDate != null || data[i].toDate != "") {
              if (data[i].flag === "C" && data[i].unFlagBy == null) {
                if(station == "All Stations"){
                  this.dataOnGoing.push(data[i]);
                }
                else{
                  if (data[i].stationId === station) {
                    this.dataOnGoing.push(data[i]);
                  }
                }
              }
            }
            else{
              console.log("something wrong");
            }
          }
          // console.log(this.dataOnGoing);
          this.tbodySummaryCalibration = this.dataOnGoing;

          for(let i in this.tbodySummaryCalibration){
            this.tbodySummaryCalibration[i]["checkbox"] = false;
          // console.log(this.tbodySummaryCalibration[i]);
          }

          this.loadingTable = false;

          // console.log(this.tbodySummaryCalibration);
          
      })
      .catch(error => {
          console.log(error);
          this.loadingTable = false;
      })

    },

    selectValueStation: function(e){
      this.stationSelected = e.split(" - ")[0];

      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    // selectMonthDate: function(e){

    // },

    // selectEndDate: function(e){

    // },

    generateTable(){
      // console.log(this.stationSelected);
      if (this.stationSelected != null) {
        this.loadingTable = true;
        this.disableBtn = false;
        this.loadDataCalibrationRecord(this.stationSelected,this.monthDate,this.yearDate);
      }
    },

    check(item,event){ 
      console.log(item, event);
      // console.log(item.checkbox);

      // console.log(item.checkbox);
      // if (item.checkbox === null) {
      //   item.checkbox = false;
      // }

      if (item.checkbox === true) {
          this.checkboxSelected.push(item);
          if (this.checkboxSelected.length == this.dataOnGoing.length) {
            this.textSelect = "DESELECT";
            this.selectAll = !this.selectAll
            this.valueCheckbox = true;
          }
      }
      else if (item.checkbox === false || item.checkbox == null){
          for (let i = 0; i < this.checkboxSelected.length; i++) {
              if (this.checkboxSelected[i].stationId == item.stationId
              && this.checkboxSelected[i].parameter == item.parameter
              && this.checkboxSelected[i].fromDatetime == item.fromDatetime) {
                  this.checkboxSelected.splice(i,1);
              }
          }
          if (this.checkboxSelected.length == 0) {
            this.textSelect = "SELECT";
            this.selectAll = !this.selectAll
            this.valueCheckbox = false;
          }
      }

      // // this.checkboxSelected.push(item);
      console.log(this.checkboxSelected);

    },

    allCheck(){

      this.checkboxSelected = [];

      this.selectAll = !this.selectAll

      if (this.selectAll == true) {
        this.textSelect = "DESELECT";
        for(let i in this.tbodySummaryCalibration){
          this.tbodySummaryCalibration[i]["checkbox"] = true;
          this.checkboxSelected.push(this.tbodySummaryCalibration[i]);
        }
        console.log(this.checkboxSelected);
        this.valueCheckbox = true;
      }
      else{
        this.textSelect = "SELECT";
        for(let i in this.tbodySummaryCalibration){
          this.tbodySummaryCalibration[i]["checkbox"] = false;
        }
        this.checkboxSelected = [];
        this.valueCheckbox = false;
      }

      console.log(this.checkboxSelected);
    },

    unflag(){

      if (this.checkboxSelected.length > 0) {

        // for(let i in this.checkboxSelected){
        //   if (this.checkboxSelected[i]["datetime"] != null) {
        //     this.checkboxSelected[i]["datetime"] = this.convertToDbDt(this.checkboxSelected[i]["datetime"].split("<br>")[0]) + "T" + this.convert24Hours(this.checkboxSelected[i]["datetime"].split("<br>")[1]);
        //   }
        //   if (this.checkboxSelected[i]["fromDatetime"] != null) {
        //     this.checkboxSelected[i]["fromDatetime"] = this.convertToDbDt(this.checkboxSelected[i]["fromDatetime"].split("<br>")[0]) + "T" + this.convert24Hours(this.checkboxSelected[i]["fromDatetime"].split("<br>")[1]);
        //   }
        // }

        for(let i in this.checkboxSelected){
          if (this.checkboxSelected[i]["datetime"] != null) {
            this.checkboxSelected[i]["datetime"] = this.converttoDBFormat(this.checkboxSelected[i]["datetime"]);
          }
          if (this.checkboxSelected[i]["fromDatetime"] != null) {
            this.checkboxSelected[i]["fromDatetime"] = this.converttoDBFormat(this.checkboxSelected[i]["fromDatetime"]);
          }
        }


        let objJson = JSON.stringify(this.checkboxSelected);
        console.log(objJson);

        axios.put(this.globalUrl+'maint/endmaintcal', objJson, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response.data);
            this.dialogSuccessUnflag = true;
        })
        .catch(error => {
            console.log(error);
            this.dialogSuccessUnflag = false;
            this.dialogErrorUnflag = true;
        })
      }

    },

    convert12Hours(time) {
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
        return time;
    },
    
    convertDateFormat(date){
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;
        return date;
    },

    converttoDBFormat(date){

    let tar = date;
    let dt;

    if (tar.includes(" AM")){
        dt = tar.replace(" AM", "_AM")
    } else {
        dt = tar.replace(" PM", "_PM")
    }

    dt = dt.split("<br>")
    let tarikh = dt[0];
    let masa = dt[1];



    if (masa.includes("_AM")){
        if (masa.split(":")[0] < 10) {
            masa = "0"+masa.replace("_AM","")
        } else if (masa.split(":")[0] == 12) {
            masa = masa.replace("_AM","").replace("12","00")
        }
        else {
                masa = masa.replace("_AM","")
        }
    } else {
        masa = masa.replace("_PM","")
        if (masa.split(":")[0] != "12") {
            masa = (parseInt(masa.split(":")[0]) + 12) + ":" + masa.split(":")[1] + ":" + masa.split(":")[2]
        }
    }

    // console.log("masa",masa)

    tarikh = tarikh.split("/")[2] + "-" + tarikh.split("/")[1] + "-" + tarikh.split("/")[0]

    date = tarikh + "T" + masa
    return date;

    // console.log(date)
    },
      
  },
  mounted(){
    this.load();
    
  }

}
</script>

<style lang="scss">

@import '~scss/main';

.v-subheader {
  height: 30px;
}

.col-dateYear{
  display: flex !important;
}
</style>